import ThemisExport from"@/components/analytics/export"
import ThemisInsights from "@/components/analytics/insights"
import { mapGetters, mapActions } from "vuex"
import { ANCHOR_LINKS } from "@/constants"

export default {
  name      : "Analytics",
  components: {
    ThemisExport,
    ThemisInsights
  },
  data() {
    return {
      analyticsTab: null,
      tabs        : {
        [ANCHOR_LINKS.INSIGHTS]: 0,
        [ANCHOR_LINKS.EXPORT]  : 1
      }
    }
  },
  computed: {
    ...mapGetters({
      isAddingExport       : "exports/isAddingExport",
      exports              : "exports/exports",
      latestAnalyticsExport: "exports/latestAnalyticsExport",
      isExportAdded        : "exports/isExportAdded",
      kpis                 : "kpis/kpis",
      domains              : "domains/domains",
      channels             : "channels/channels",
      languages            : "languages/languages",
      loggedInUserPolicies : "accessControl/loggedInUserPolicies"
    }),
    showInsightsTab() {
      return this.checkPropertyInAnalyticsViewWhere("insights")
    },
    showExportsTab() {
      return this.checkPropertyInAnalyticsViewWhere("export")
    }
  },
  methods: {
    ...mapActions({
      addExport         : "exports/addExport",
      loadExport        : "exports/loadExport",
      downloadDataExport: "exports/downloadDataExport"
    }),
    handleTabClickEvent(hash) {
      this.analyticsTab = this.tabs[hash] || this.tabs[ANCHOR_LINKS.INSIGHTS]
      if (this.$route.hash !== hash) {
        this.$router.push({ hash })
      }
    },
    checkPropertyInAnalyticsViewWhere(property) {
      const analyticsViewPolicies = this.loggedInUserPolicies["Analytics view"]
      return analyticsViewPolicies && analyticsViewPolicies.some(analyticsViewPolicy =>
        analyticsViewPolicy.wheres && analyticsViewPolicy.wheres.some(where => where.type === property)
      )
    }
  },
  watch: {
    "$route.hash": {
      immediate: true,
      handler  : function(hash) {
        if (hash) {
          if (hash === ANCHOR_LINKS.EXPORT && this.checkPropertyInAnalyticsViewWhere("export")) {
            this.handleTabClickEvent(hash)
          } else if (hash === ANCHOR_LINKS.INSIGHTS && this.checkPropertyInAnalyticsViewWhere("insights")) {
            this.handleTabClickEvent(hash)
          } else {
            this.$router.push({ name: "forbidden" })
          }
        }
      }
    }
  }
}